import React, { ReactElement, useState } from "react";
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Meta } from '../components/Meta';
import axsGIF from '../images/axs-video.gif';

interface Props {}

function Index(_props: Props): ReactElement {
  return (
    <>
    <Meta title="Flima | NFT + DeFi + Game Unlock Rich Earning Opportunity" bodyClasses="" />
    <div id="container">
      <Header darkMode={true} />
      <section className="hero-section container mx-auto" id="hero">
        <div className="hero-section--container flex md:flex-row flex-col md:p-3">
          <div className="md:w-1/2 pr-0 md:pr-3">
            <h1 className="hero--title md:px-0 px-4"><span className="text-pink">NFT + DeFi + Game</span><br />Unlock Rich<br /> Earning<br /> Opportunities</h1>
            <p className="hero--description px-4 mt-4 mb-1.5 md:px-0 md:mt-8">Earn yield faster and more flexible on the most liquid decentralized NFT marketplace.</p>
            <button id="btn_launch-app" className="btn_launch-app btn mt-8 mx-auto md:ml-0 md:mt-16 btn-primary flex items-center"><b>Launch App</b>&nbsp;(Coming soon)<img className="arrow-icon object-contain ml-2 md:ml-4" src="https://cdn-static.ymeet.me/flima/right-arrow-white.svg" /></button>
          </div>
          <div className="md:w-1/2 pl-0 md:pl-3">
            <div className="hero-image-blur">
              <div>
                <img className="object-contain" src="https://cdn-static.ymeet.me/flima/hero-image1.png" />
              </div>
              <div className="flex">
                <img className="w-1/2 object-contain pr-3 pt-4 md:pt-6" src="https://cdn-static.ymeet.me/flima/hero-image2.png" />
                <img className="w-1/2 object-contain pl-3 pt-4 md:pt-6" src="https://cdn-static.ymeet.me/flima/hero-image3.png" />
              </div>
              <div className="flex">
                <img className="w-2/3 object-contain pr-3 pt-4 md:pt-6" src="https://cdn-static.ymeet.me/flima/hero-image4.png" />
                <img className="w-1/3 object-contain pl-3 pt-4 md:pt-6" src="https://cdn-static.ymeet.me/flima/hero-image5.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features-section container mx-auto" id="features">
        <div className="section-heading mt-16 md:mt-32">
          <h1 className="flex justify-center"><img className="object-contain w-8 mr-3" src="https://cdn-static.ymeet.me/flima/star-filled-smoothray.svg" />Features<img className="object-contain w-8 ml-3" src="https://cdn-static.ymeet.me/flima/star-filled-smoothray.svg" /></h1>
          <p className="w-full md:w-1/2 md:px-0 px-4 mx-auto text-center">Don’t waste your valuable items in the inelastic in-game market.<br /><b className="font-interBold">Flima</b> provides a flexible and safe market to maximize your profit.</p>
        </div>
        <div className="section-content px-4 md:px-0">
          <div className="flex mb-0 md:mb-8 md:flex-row flex-col">
            <div className="features features-2 w-full mb-4 md:mb-0 md:w-1/3 md:mx-4">
              <span></span>
              <span></span>
              <img className="object-contain w-20" src="https://cdn-static.ymeet.me/flima/feature2.png" />
              <h4>Lending Protocol </h4>
              <p>Add more flexibility to your earning strategy by borrowing in-game currency using in-game NFT or stable coin as collateral.</p>
            </div>
            <div className="features features-4 w-full mb-4 md:mb-0 md:w-1/3 md:mx-4">
              <span></span>
              <span></span>
              <img className="object-contain w-20" src="https://cdn-static.ymeet.me/flima/feature5.png" />
              <h4>Fractionalized NFT</h4>
              <p>Invest as little as $100 in the expensive NFTs through our tokenized NFT.
                Maximize your earnings from transaction fee in the vault.</p>
            </div>
            <div className="features features-1 w-full mb-4 md:mb-0 md:w-1/3 md:mx-4">
              <span></span>
              <span></span>
              <img className="object-contain w-40" src="https://cdn-static.ymeet.me/flima/feature1.png" />
              <h4>P2P  Market and Auction</h4>
              <p><b className="text-pink font-interBold">Flima</b> provides a decentralized P2P escrow market and auction for your rare items.</p>
            </div>
          </div>
          <div className="flex justify-center md:flex-row flex-col">
            <div className="features features-3 w-full mb-4 md:mb-0 md:w-1/3 md:mx-4">
              <span></span>
              <span></span>
              <img className="object-contain w-20" src="https://cdn-static.ymeet.me/flima/feature3.png" />
              <h4>Affiliate</h4>
              <p><b className="text-pink font-interBold">Flima</b> allows affiliates to earn up to 10% from all successful buy/ sell offers.</p>
            </div>
            <div className="features features-5 w-full md:w-1/3 md:mx-4">
              <span></span>
              <span></span>
              <img className="object-contain w-20" src="https://cdn-static.ymeet.me/flima/feature4.png" />
              <h4>Multi Chain Support</h4>
              <p>We support assets deposits and withdrawals across multiple blockchains to and from <b className="text-pink font-interBold">Flima</b></p>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-section container mx-auto" id="why-choose">
        <div className="section-heading mt-16 md:mt-32">
          <h1 className="flex justify-center">Why Flima?<img className="object-contain ml-2.5 w-7 md:w-14" src="https://cdn-static.ymeet.me/flima/icon-heading.svg" /></h1>
          <p className="w-full md:w-1/2 mx-auto text-center">More liquidity for your NFT games.</p>
          <div className="w-full h-32 px-4 md:w-3/5 md:h-64 m-auto mt-12">
            <img src={axsGIF} className="object-cover mx-auto w-full h-32 md:w-3/5 md:h-64" alt="Otter dancing with a fish" />
          </div>
        </div>
        <div className="section-content">
          <div className="flex md:flex-row flex-col">
            {/* <div className="why-choose--container flex flex-col relative items-center md:w-1/4">
              <img className="object-contain w-36 z-10 absolute" src="https://cdn-static.ymeet.me/flima/why-choose-4.png" />
              <div className="why-choose flex flex-col relative items-center">
                <div className="deco-4 absolute inset-auto"></div>
                <h4 className="text-center z-10">Focusing on in-game currencies</h4>
              </div>
            </div>  */}
            <div className="why-choose--container flex flex-col relative items-center md:w-1/3">
              <img className="object-contain w-36 z-10 absolute" src="https://cdn-static.ymeet.me/flima/why-choose1.png" />
              <div className="why-choose flex flex-col relative items-center">
                <div className="deco-1 absolute inset-auto"></div>
                <h4 className="text-center z-10">Flexible ways to trade your rare items at preferred prices</h4>
              </div>
            </div>
            <div className="why-choose--container flex flex-col relative items-center md:w-1/3">
              <img className="object-contain w-36 z-10 absolute" src="https://cdn-static.ymeet.me/flima/why-choose2.png" />
              <div className="why-choose flex flex-col relative items-center">
                <div className="deco-2 absolute inset-auto"></div>
                <h4 className="text-center z-10">Attractive affiliate program to boost your offer</h4>
              </div>
            </div>
            <div className="why-choose--container flex flex-col relative items-center md:w-1/3">
              <img className="object-contain w-36 z-10 absolute" src="https://cdn-static.ymeet.me/flima/why-choose3.png" />
              <div className="why-choose flex flex-col relative items-center">
                <div className="deco-3 absolute inset-auto"></div>
                <h4 className="text-center z-10">Only verified cryptoassets to be listed</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="roadmap-section container mx-auto" id="roadmap">
        <div className="section-heading mb-12 mt-16 md:mt-32">
          <h1 className="flex justify-center"><img className="object-contain w-8 mr-3" src="https://cdn-static.ymeet.me/flima/star-filled-smoothray.svg" />Roadmap<img className="object-contain w-8 ml-3" src="https://cdn-static.ymeet.me/flima/star-filled-smoothray.svg" /></h1>
        </div>
        <div className="section-content">
          <div className="timeline mx-4">
            <img className="object-contain w-3 absolute top-20 arrow-moblie -rotate-90 md:rotate-0 left-1/2 md:left-0 md:top-1/2 -mt-2.5" src="https://cdn-static.ymeet.me/flima/left-arrow.svg" />
            <img className="object-contain hidden md:block w-3 absolute top-1/2 right-0 -mt-2.5" src="https://cdn-static.ymeet.me/flima/right-arrow.svg" />
            <div className="timeline-progress hidden md:block"></div>
            <div className="flex justify-evenly flex-col md:flex-row">
              <div className="roadmap relative active timeline--top roadmap-bg1">
                <div className="dot glow"></div>
                <h4 className="roadmap--title">Phase 1</h4>
                <ul className="list-disc pl-4">
                  <li className="mb-2">Funding.</li>
                </ul>
                <span className="step active">2022 Q3</span>
              </div>
              <div className="roadmap relative timeline--top hidden roadmap-bg1 md:block">
                <h4 className="roadmap--title">Phase 3</h4>
                <ul className="list-disc pl-4">
                  <li className="mb-2">Launch Fractionalized NFTs and Affiliate on BSC Testnet.</li>
                  <li>Integrate with 6 more blockchain games.</li>
                </ul>
                <span className="step">2023 Q1</span>
              </div>
              <div className="roadmap relative timeline--top md:hidden roadmap-bg2">
                <h4 className="roadmap--title">Phase 2</h4>
                <ul className="list-disc pl-4">
                  <li className="mb-2">Launch Lending Protocol & P2P market on BSC Testnet.</li>
                  <li>Integrate with 1 popular blockchain game.</li>
                </ul>
                <span className="step">2022 Q4</span>
              </div>
            </div>
            <div className="flex justify-evenly items-start flex-col md:flex-row">
              <div className="roadmap relative timeline--bottom hidden roadmap-bg2 md:block">
                <h4 className="roadmap--title">Phase 2</h4>
                <ul className="list-disc pl-4">
                  <li className="mb-2">Launch Lending Protocol & P2P market on BSC Testnet.</li>
                  <li>Integrate with 1 popular blockchain game.</li>
                </ul>
                <span className="step">2022 Q4</span>
              </div>
              <div className="roadmap relative timeline--bottom md:hidden roadmap-bg1">
                <h4 className="roadmap--title">Phase 3</h4>
                <ul className="list-disc pl-4">
                  <li className="mb-2">Launch Fractionalized NFTs and Affiliate on BSC Testnet.</li>
                  <li>Integrate with 6 more blockchain games.</li>
                </ul>
                <span className="step">2023 Q1</span>
              </div>
              <div className="roadmap relative timeline--bottom roadmap-bg3 last">
                <h4 className="roadmap--title">Phase 4</h4>
                <ul className="list-disc pl-4">
                  <li>Launch on Mainnet.</li>
                </ul>
                <span className="step">2023 Q2</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="token-economic-section relative" id="token_distribute">
        <div className="deco-1 absolute top-1/2 left-0 xl:block hidden"></div>
        <div className="deco-2 absolute top-1/3 right-0 xl:block hidden"></div>
        <div className="container mx-auto">
          <div className="section-heading mt-20 md:mt-32">
            <h1 className="flex justify-center">Token Distribution<img className="object-contain ml-2.5 w-7 md:w-14" src="https://cdn-static.ymeet.me/flima/icon-heading.svg" alt="icon" /></h1>
            <p className="w-full md:w-1/2 mx-auto text-center">
              <span className="text-pink font-interBold">$FLIMA</span> is the governance token of our platform. You can stake <span className="text-pink font-interBold">$FLIMA</span> to earn <span className="text-pink font-interBold">$tFLIMA</span>, a utility token.  All the consumed tokens will be burned.
            </p>
          </div>
          <div className="section-content md:px-4">
            <div className="token-economic--container">
              <img className="object-contain w-full" src="https://cdn-static.ymeet.me/flima/tokeneco-bg.png?a=1" alt="ecosystem background" />
              <div className="token-economic--wrapper">
                <div className="flex justify-around flex-col md:flex-row">
                  <div className="token-economic flex w-full md:w-1/4 flex-col items-center justify-center">
                    <h4 className="token-economic--title big">256<small>M</small> FLIMA</h4>
                    <h3 className="token-economic--subtitle top">TOTAL TOKENS</h3>
                  </div>
                  <div className="flex flex-wrap justify-around w-full md:w-3/4">
                    <div className="token-economic flex flex-col items-center">
                      <div>
                        <h4 className="token-economic--title">51.2<small>M</small> <span>(20%)</span></h4>
                        <h3 className="token-economic--subtitle">Team</h3>
                      </div>
                      <div>
                        <h4 className="token-economic--title">10<small>M</small> <span>(11.7%)</span></h4>
                        <h3 className="token-economic--subtitle">Private Sale</h3>
                      </div>
                      <div>
                        <h4 className="token-economic--title">25<small>M</small> <span>(9.77%)</span></h4>
                        <h3 className="token-economic--subtitle">Marketing</h3>
                      </div>
                    </div>
                    <div className="token-economic flex flex-col items-center">
                      <div>
                        <h4 className="token-economic--title">12.8<small>M</small> <span>(5%)</span></h4>
                        <h3 className="token-economic--subtitle">Founder</h3>
                      </div>
                      <div>
                        <h4 className="token-economic--title">15<small>M</small> <span>(5.86%)</span></h4>
                        <h3 className="token-economic--subtitle">Public Sale</h3>
                      </div>
                      <div>
                        <h4 className="token-economic--title">100<small>M</small> <span>(39%)</span></h4>
                        <h3 className="token-economic--subtitle">Reserve</h3>
                      </div>
                      
                    </div>
                    <div className="token-economic flex flex-col items-center">
                      <div>
                        <h4 className="token-economic--title">18<small>M</small> <span>(7%)</span></h4>
                        <h3 className="token-economic--subtitle">Seed</h3>
                      </div>
                      <div>
                        <h4 className="token-economic--title">4<small>M</small> <span>(1.56%)</span></h4>
                        <h3 className="token-economic--subtitle">Liquidity</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mt-4">
                  <button id="btn_buy-token" className="btn_buy-token btn btn-primary flex items-center">
                    <b>Buy Token</b>&nbsp;(Coming soon) <img className="arrow-icon object-contain ml-2 md:ml-4" src="https://cdn-static.ymeet.me/flima/right-arrow-white.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="newsletter-section md:mt-60 mt-20 px-4 md:px-0 relative" id="contact">
        <div className="deco-4 absolute top-0 left-0 xl:block hidden"></div>
        <div className="deco-5 absolute top-1/3 right-0 xl:block hidden"></div>
        <div className="container mx-auto">
          <div className="newsletter-section--container relative md:mx-4">
            {/* <div className="deco-1 absolute top-8 left-8"></div>
            <div className="deco-2 absolute bottom-0 left-1/3"></div>
            <div className="deco-3 absolute top-16 right-1/4"></div> */}
            <div className="section-heading w-full md:w-1/2">
              <div className="flex flex-col items-center md:items-start">
                <h1 className="flex justify-center">Never miss a drop <img className="object-contain ml-2.5 w-7 md:w-14" src="https://cdn-static.ymeet.me/flima/star-combo.svg" /></h1>
                <p className="w-full md:w-9/12 text-center md:text-left">Be the first to receive Flima updates, announcements and sneak peeks.</p>
                <a href="https://linktr.ee/flima.io" target="_blank" id="link_join-our-community" className="btn btn-primary flex items-center w-full md:w-auto justify-center"><b>Join Our Community</b> <img className="arrow-icon object-contain ml-2 md:ml-4" src="https://cdn-static.ymeet.me/flima/right-arrow-white.svg" /></a>
              </div>
            </div>
            <div className="section-content w-full md:w-1/2 md:mt-0 mt-20 static md:absolute bottom-0 right-0">
              <img className="object-contain w-5/6 mx-auto md:ml-auto" src="https://cdn-static.ymeet.me/flima/newst.png" />
            </div>
          </div>
        </div>
      </section>
      <Footer darkMode={true} />
    </div>
    </>
  )
}

export default Index
